import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Badge} from "primereact/badge";
import {Dropdown} from "primereact/dropdown";
import moment from "moment";
import axios from "axios";

const ProductionitemSubcontractorListComponent = (props) => {

  const {t} = props;
  const [productionitemSubcontractors, setProductionitemSubcontractors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(Number(process.env.REACT_APP_LIST_ROW_DEFAULT));
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: -1,
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderProductionitemSubcontractorList: false})
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_HOST + "/api/v2/production-item/subcontractor-list" + "/?" + JSON.stringify(lazyParams).replace('"multiSortMeta":[],', ''),
        params: {},
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
          'App-Network': localStorage.getItem("networkId")
        }
      })
        .then(response => {
          setTotalRecords(response.data.data.total_item_count);
          setProductionitemSubcontractors(response.data.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: Number(process.env.REACT_APP_LIST_ROW_OP1), value: Number(process.env.REACT_APP_LIST_ROW_OP1)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP2), value: Number(process.env.REACT_APP_LIST_ROW_OP2)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP3), value: Number(process.env.REACT_APP_LIST_ROW_OP3)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP4), value: Number(process.env.REACT_APP_LIST_ROW_OP4)},
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const deleteProductionitemSubcontractor = (rowData) => {
    // ProductionitemSubcontractorDeleteService(rowData.id)
    //   .then(response => {
    //     window.App.toastShow('success', 'Sikeres törlés!',);
    //     loadLazyData();
    //   })
    //   .catch(error => {
    //     window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
    //   })
  }
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-pencil" className="  p-button-warning p-mr-2"
                tooltip="Módosítás"
                onClick={() => {
                  window.App.setState({
                    sidebarProductionitemSubcontractorUpdate: true,
                    productionitemSubcontractorFormMethod: 'update',
                    productionitemSubcontractorRowData: rowData
                  })
                }}/>
      </React.Fragment>
    );
  }

  const [statuses, setStatuses] = useState([]);
  const status = (rowData) => {
    if (statuses && statuses[0]) {
      let status = statuses.filter(item => item.id === rowData.status)
      if (status[0] && status[0].name) {
        return status[0].name
      }
    }
  }

  useEffect(() => {
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderProductionitemSubcontractorList === true])

  return (
    <>
      <DataTable size={localStorage.getItem('scale')}
                 emptyMessage="Nincs találat."
                 value={productionitemSubcontractors} paginator first={lazyParams.first} totalRecords={totalRecords}
                 onPage={onPage}
                 onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
                 onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
                 paginatorTemplate={pager} rows={rows}
                 autoLayout={true}
                 filterDisplay="row"
                 responsiveLayout="scroll"
                 stripedRows
                 dataKey="id"
      >
        <Column field="project_number" header={t('projectNumber')} sortable filter></Column>
        <Column field="project_item_name" header={t('project') + " tétel"} sortable filter></Column>
        <Column field="external_reference" header={t('externalReference')} sortable filter></Column>
        <Column field="technology_name" header={"Technológia"} sortable filter></Column>
        <Column field="partner_name" header={t('partnerName')} sortable filter></Column>
        <Column field="subcontractor_name" header={"Alvállalkozó"} sortable filter></Column>
        <Column field="status" header={"Státusz"} sortable filter></Column>
        <Column field="deadline" header={t('deadline')} sortable className={"p-text-nowrap"} body={(rowData) => {
          if (rowData.deadline && rowData.deadline !== null) {
            return (
              <>
                {(rowData.status !== 'done' || rowData.status !== 'deleted') &&
                <>
                  <Badge value={moment.utc(rowData.deadline).local().format('YYYY-MM-DD')} severity="warning"></Badge>
                </>
                }
                {(rowData.status === 'deleted') &&
                <span className={"p-text-strikethrough"}>
                  {moment.utc(rowData.deadline).local().format('YYYY-MM-DD')}
                </span>
                }
                {(rowData.status === 'done') &&
                  <>
                    <Badge value={moment.utc(rowData.deadline).local().format('YYYY-MM-DD')} severity="success"></Badge>
                  </>
                }
              </>
            )
          }
        }}></Column>
        <Column field="created_at_snapshot" header={"Létrehozva"} sortable className={"p-text-nowrap"} body={(rowData) => {
          if (rowData.created_at_snapshot && rowData.created_at_snapshot !== null) {
            return (
              <>{moment.utc(rowData.created_at_snapshot).local().format('YYYY-MM-DD HH:mm:ss')}</>
            )
          }
        }}></Column>
        <Column field="updated_at_snapshot" header={"Módosítva"} sortable className={"p-text-nowrap"} body={(rowData) => {
          if (rowData.updated_at_snapshot && rowData.updated_at_snapshot !== null) {
            return (
              <>{moment.utc(rowData.updated_at_snapshot).local().format('YYYY-MM-DD HH:mm:ss')}</>
            )
          }
        }}></Column>
        <Column body={actionBodyTemplate} className={"p-py-0 p-text-right p-text-nowrap"}></Column>
      </DataTable>

    </>
  )
}
export default withTranslation('common')(ProductionitemSubcontractorListComponent);
